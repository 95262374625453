export function useCustomerIO() {
  const { $cio } = useNuxtApp()
  /**
   * Calls the Customer.io identify function which is necessary for "in-app" messaging
   */
  function customerIOIdentify(
    id: number | string,
    data: {
      email: string
      created_at?: number
    },
  ) {
    $cio.identify(id.toString(), data)
  }

  /**
   * Calls the Customer.io reset function
   */
  function customerIOReset() {
    $cio.reset()
  }

  return {
    customerIOIdentify,
    customerIOReset,
  }
}
